import React, { useState } from "react";

import { HomeContainer } from './homeStyled'

import titulo from '../../assets/images/logo-es-autismo.png'
import logoAcademia from '../../assets/images/logo_academia_latam.png'
import foto from '../../assets/images/foto1.png'
import { PageContainer } from "../../components/PageContainer";
import { ILead } from "../../types/lead";
import { useNavigate } from "react-router-dom";
import { useQuestionContext } from "../../context/questionPovider";

export interface Props { }

const Home: React.FC<Props> = ({ ...props }) => {
    const [leadData, setLeadData] = useState<ILead>({} as ILead)
    const [loading, setLoading] = useState<boolean>(false)
    const { saveLead } = useQuestionContext()!
    const navigate = useNavigate()

    const handleLeadData = (e: any, field: "nome" | "email" | "nome_paciente" | "idade_paciente") => {
        setLeadData({ ...leadData, [field]: e.target.value })
    }
    const startTest = async () => {
        const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

        const idadeRegEx = /^\d+$/

        if (!leadData.nome) return alert('Por favor insira o seu nome.')
        if (!leadData.email || !emailRegEx.test(leadData.email)) return alert('Por favor insira um email válido.')
        if (!leadData.nome_paciente) return alert('Por favor insira o nome da criança ou paciente.')
        if (
            !leadData.idade_paciente ||
            !idadeRegEx.test(leadData.idade_paciente.toString()) ||
            leadData.idade_paciente.toString().length > 2
        ) return alert('Por favor insira a idade da criança ou paciente. Insira um número maior que zero e menor que 100.')

        setLoading(true)

        try {
            await saveLead(leadData)

            navigate('/questionario')

        } catch (error) {
            alert('Houve um erro, por favor tente novamente mais tarde.')
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <PageContainer>
            <HomeContainer>
                <div className="left">
                    <h1><img src={logoAcademia} alt="Academia del Autismo" /></h1>
                    <h2><img src={titulo} alt="¿Es Autismo?" /></h2>
                    <p>
                        El Trastorno del Espectro Autista (TEA) es un trastorno del neurodesarrollo caracterizado por déficits persistentes en la interacción, la comunicación social y la presencia de comportamientos e intereses restringidos y repetitivos (American Psychiatric Association, 2013). El siguiente test presenta algunas características del Trastorno del Espectro Autista en base a escalas ampliamente utilizadas a nivel mundial, como M-CHAT y CARS.<br /><br />
                        <span>¡ADVERTENCIA! El resultado no debe considerarse diagnóstico de ninguna manera. Solo los Profesionales de la Salud pueden realizar esta valoración.</span>
                    </p>
                    <div className="form">
                        <input onChange={(e) => handleLeadData(e, 'nome')} name="name" type="text" placeholder="Tu Nombre" required />
                        <input onChange={(e) => handleLeadData(e, 'email')} name="email" type="email" placeholder="Tu correo electronico" required />
                        <input onChange={(e) => handleLeadData(e, 'nome_paciente')} name="patientName" type="text" placeholder="Nombre del niño(a)" required />
                        <input onChange={(e) => handleLeadData(e, 'idade_paciente')} name="patientAge" type="number" placeholder="Edad del niño(a)" required />
                        <button onClick={startTest} disabled={loading}>Comenzar</button>
                    </div>
                </div>
                <div className="right">
                    <h1><img src={logoAcademia} alt="Academia del Autismo" /></h1>
                    <div><img src={foto} alt="" aria-hidden="true" /></div>
                </div>
            </HomeContainer>
        </PageContainer>
    );
};

export { Home };
