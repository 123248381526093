import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "../../components/PageContainer";

import { QuestionsContainer } from './questionsStyled'

import { IAlternative, IFinalAnswer, IQuestion } from "../../types/question";

import BaseService from "../../services/base.service";

import logoAcademia from '../../assets/images/logo_academia_latam.png'
import foto2 from '../../assets/images/foto2.png'
import titulo from '../../assets/images/logo-es-autismo.png'
import loadingGif from '../../assets/images/loading.gif'
import { useQuestionContext } from "../../context/questionPovider";
import { useNavigate } from "react-router-dom";



export interface Props { }

interface IQuestionSteps {
    [key: number]: IQuestion[]
}

const Questions: React.FC<Props> = ({ ...props }) => {
    const [questions, setQuestions] = useState<IQuestionSteps>({})
    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingPost, setLoadingPost] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<number>(1)
    const { saveAnswers } = useQuestionContext()!
    const navigate = useNavigate()

    const getQuestions = useCallback(async () => {
        setLoading(true)
        const service = new BaseService('')

        try {
            const response = await service.list();

            const inter = response.questoes.map((question: IQuestion, index: number) => {
                const alt = question.alternativas.find((alternativa: IAlternative) => alternativa.pontuacao === 0)
                return {
                    ...question,
                    resposta: alt ? alt.id : question.alternativas[1].id,
                    checkState: false,
                    numero: index + 1
                }
            })
            const steps: IQuestionSteps = {
                1: inter.slice(0, 10),
                2: inter.slice(10, 20),
                3: inter.slice(20),
            }

            setQuestions(steps)
        } catch (error) {
            console.error(error)
            setError(true)
        } finally {
            setLoading(false)
        }
    }, [])

    const chekQuestion = (step: number, question: IQuestion) => {
        if (!step || !question) return;

        const foundIndex = questions[step].findIndex(quest => quest.id === question.id)

        const newck = !questions[step][foundIndex].checkState

        const newFound = questions[step][foundIndex] = {
            ...questions[step][foundIndex],
            checkState: newck,
            resposta: newck ? questions[step][foundIndex].alternativas[0].id : questions[step][foundIndex].alternativas[1].id
        }

        let nq = [...questions[step]]

        nq[foundIndex] = newFound

        setQuestions({ ...questions, [step]: nq })

    }

    const moveStep = (direction: string) => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 20);
        if (direction === 'fw' && currentStep < 3) {
            setCurrentStep(currentStep + 1)
        }
        if (direction === 'pv' && currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const finish = async () => {
        setLoadingPost(true)
        const finalArray: IFinalAnswer[] = [...questions[1], ...questions[2], ...questions[3]]
        try {
            await saveAnswers(finalArray)

            navigate('/resultado')
        } catch (error) {
            console.error(error)
        }
        setLoadingPost(false)
    }

    useEffect(() => {
        getQuestions()
    }, [getQuestions])

    return (
        <PageContainer>
            <QuestionsContainer>
                <div className="left">
                    <h1><img src={logoAcademia} alt="Academia do Autismo" /></h1>
                    <h2><img src={titulo} alt="¿Es Autismo?" /></h2>
                    <div className="questions">
                        {error && <p>Hubo un error</p>}
                        {loading &&
                            <div className="loading">
                                <img src={loadingGif} aria-hidden="true" alt="loading" width="40" />
                            </div>
                        }
                        {questions[1] ?
                            (<>
                                <div className="paginas">Página {currentStep} / 3</div>
                                <p className="desc">Marque las opciones que mejor describan a su hijo/hija.</p>
                                <div className={`form ${currentStep === 1 ? `active` : currentStep > 1 ? 'past' : 'next'}`}>
                                    {questions[1].map((question: IQuestion) =>
                                        <label key={question.id}>
                                            <input type="checkbox" onChange={() => chekQuestion(1, question)} checked={question.checkState} name="" id="" />
                                            <span dangerouslySetInnerHTML={{ __html: `${question.numero ? `${question.numero} - ` : ''}${question.enunciado}` }}></span>
                                        </label>
                                    )}
                                </div>
                                <div className={`form ${currentStep === 2 ? `active` : currentStep > 2 ? 'past' : 'next'}`}>
                                    {questions[2].map((question: IQuestion) =>
                                        <label key={question.id}>
                                            <input type="checkbox" onChange={() => chekQuestion(2, question)} checked={question.checkState} name="" id="" />
                                            <span dangerouslySetInnerHTML={{ __html: `${question.numero ? `${question.numero} - ` : ''}${question.enunciado}` }}></span>
                                        </label>
                                    )}
                                </div>
                                <div className={`form ${currentStep === 3 ? `active` : currentStep > 3 ? 'past' : 'next'}`}>
                                    {questions[3].map((question: IQuestion) =>
                                        <label key={question.id}>
                                            <input type="checkbox" onChange={() => chekQuestion(3, question)} checked={question.checkState} name="" id="" />
                                            <span dangerouslySetInnerHTML={{ __html: `${question.numero ? `${question.numero} - ` : ''}${question.enunciado}` }}></span>
                                        </label>
                                    )}
                                </div>
                            </>)
                            : ''}
                        <div className="form active buttons">
                            <button onClick={() => moveStep(`pv`)} disabled={currentStep === 1} className="text">Regresar</button>
                            {currentStep === 3 ?
                                <button onClick={() => finish()} disabled={loadingPost}>Finalizar</button>
                                :
                                <button onClick={() => moveStep('fw')} disabled={currentStep === 3}>Siguiente</button>
                            }

                        </div>
                    </div>
                </div>
                <div className="right">
                    <h2><img src={titulo} alt="¿Es Autismo?" /></h2>
                    <div><img src={foto2} alt="" aria-hidden="true" /></div>
                </div>
            </QuestionsContainer>
        </PageContainer>
    );
};

export { Questions };
