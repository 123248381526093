import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Home } from './pages/home'
import { Questions } from './pages/questions'
import { Results } from './pages/results'
import { NotFound } from './pages/not-found'

import { AppContainer } from './appStyled'

import logoAcademia from './assets/images/logo_academia_latam.png'
import logoB42 from './assets/images/logo-b42.png'

function TheRoute() {
    let element = useRoutes([
        {
            path: '/',
            element: <Home />,
        },
        {
            path: '/questionario',
            element: <Questions />,
        },
        {
            path: '/resultado',
            element: <Results />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ])
    return element
}

export default function Router() {
    return (
        <AppContainer>
            <TheRoute />
            <footer>
                <h6><img src={logoAcademia} alt="Academia do Autismo" /></h6>
                <h6><img src={logoB42} alt="Agência B42" /></h6>
            </footer>
        </AppContainer>
    )
}