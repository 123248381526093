import React from "react";
import { PageContainer } from "../../components/PageContainer";

import { ResultsContainer } from './resultsStyled'

import titulo from '../../assets/images/titulo.png'
import logoAcademia from '../../assets/images/logo_academia_latam.png'
import { useQuestionContext } from "../../context/questionPovider";

import { IRecommendation } from "../../types/recommendation";
import { RecommendationCard } from "../../components/RecommendationCard";

export interface Props { }

const Results: React.FC<Props> = ({ ...props }) => {
    const { result } = useQuestionContext()!;

    return (
        <PageContainer>
            <ResultsContainer>
                <header>
                    <h1><img src={logoAcademia} alt="Academia del Autismo" /></h1>
                    <h2><img src={titulo} alt="¿Es Autismo?" /></h2>
                </header>
                {result.pontuacao ? (<>
                    <main>
                        <big className="pontuacao">Puntuación Final: {result.pontuacao.split('.')[0]}</big>
                        <div className="txtResult">
                            <p>{result.texto_resumido}</p>
                            <p>En breve recibirás en tu correo electrónico un PDF con más detalles sobre tu resultado. Mientras tanto, aproveche la oportunidad para ver, a continuación, algunos contenidos que pueden serle útiles.</p>
                        </div>
                        {result.recomendacoes.length > 0 ?
                            <div className="recommendationsFlex">
                                {result.recomendacoes.map(
                                    (recommendation: IRecommendation) => <RecommendationCard recommendation={recommendation} />
                                )}
                            </div>
                            : 'Error al cargar recomendaciones'
                        }
                    </main>
                </>) : <main><div className="txtResult"><p>No hay resultados</p></div></main>}
            </ResultsContainer>
        </PageContainer>
    );
};

export { Results };
