import axios from "axios";
import { ILead } from "../types/lead";
import { IAnswerPayload } from "../types/question";

const BASE_URL = "https://nossomundoazul.com.br/api";

const api = axios.create({
	baseURL: `${BASE_URL}/avaliacoes/`,
	timeout: 10000,
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		console.group("Response Error Interceptor");
		console.log("ERROR----", error);
		console.log("ERROR----config", error.config);
		console.log("ERROR----URL", error.config.url);
		const originalReq = error.config;
		if (error.config && !error.config._retry) {
			originalReq._retry = true;
			// Return any error which is not due to authentication back to the calling service
			if ((error.response.status !== 401 && error.response.status !== 403) || error.config.url === "token/") {
				return new Promise((resolve, reject) => {
					reject(error);
				});
			}
		}
		console.groupEnd();
	}
);

export default class BaseService {
	public resource = "";
	constructor(resource: string) {
		this.resource = resource;
	}

	async list() {
		let resourceURL = `2/${this.resource}`;

		try {
			const response = await api({
				method: "GET",
				url: resourceURL,
			});
			// console.log("[BS] RESPONSE LIST ==>", response.data);

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao listar ${this.resource} base service:`, err);
			return {
				error: true,
			};
		}
	}

	async create(data: ILead) {
		try {
			const response = await api.post(`2/${this.resource}/`, data);
			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao criar ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}

	async responder(id: number, data: IAnswerPayload[]) {
		try {
			const response = await api.post(`${this.resource}/${id}/`, data);
			// console.log("[BS] RESPONSE ==> ", response);
			// console.log("[BS] RESPONSE.DATA ==> ", response);

			return response.data;
		} catch (err) {
			console.error(`[BS] Erro ao criar ${this.resource} :`, err);
			return {
				error: true,
			};
		}
	}
}
